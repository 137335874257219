@charset "UTF-8";
//@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two');
@import "../../lp3-common/scss/common-1.scss";

#lamacms-body {
  background: white;
  //max-width: 1600px;
  margin: auto;
}

.head-bar {

}

#lamacms-body .content-headblock {
  background-color: darken($blue, 25);
  background-image: url('../img/background-1.jpg');
  background-size: 150% 100%;
  height: 650px;
  @media (min-width: 768px) {
    height: 550px;
  }
  @media (min-width: 992px) {
    height: 550px;
  }
  @media (min-width: 1200px) {
    height: 600px;
  }
	@media (min-width: 1920px) {
    height: 750px;
  }

  overflow: hidden;
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
    @include transition(all 0.5s);
    &.slided-in {
      @include opacity(100);
      visibility: visible;
    }
    &.slided-out {
      @include opacity(0);
      visibility: hidden;
    }
    img {
      width: 100%;
    }
    // for ie8 bg-pic

  }
  .vidoverlay {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
    z-index: 1;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .container {
    position: relative;
    height: 100%;
    z-index: 2;
  }

  .lp-pi {
    line-height: 1.3rem;
    height: 100%;
    div {
      vertical-align: top;
    }
    h1, h2, h3, h4, p {
      color: white;
      //text-shadow:1px 1px 0 rgba(0,0,0,.5);
      font-weight: normal;
    }
    a.lp3-textlink {
      color: white;
      border-bottom-color: white;
      &:hover {
        color: white;
      }
    }

    h1 {
      font-size: 1.75rem;
      line-height:1.3rem;
      @media(min-width: 768px) {
        font-size: 2.0rem;
      }
    }

    h2 {
      font-size: 1.2rem;
      margin-top: .5em;
      margin-bottom: .5em;
      /*@media(min-width: 768px) {
        font-size: 1.5rem;
      }*/
    }

    h3 {
      font-size: 1.3rem;
      margin-top: 1em;
      margin-bottom: 0;
      @media(min-width: 992px) {
        padding: 0 1rem;
        & + p {
          padding: 0 1rem;
        }
        &.big {
          margin-bottom: .5em;
          display: block;
          text-align: center;
          &:before {
            display: block;
            margin: auto;
            font-size: 4em;
          }
        }
      }
    }

    ul.slideshow {
      position: relative;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        @include transition(all 0.3s);
        &.slided-in {
          @include opacity(100);
          visibility: visible;
          z-index: 1;
        }
        &.slided-out {
          @include opacity(0);
          visibility: hidden;
          z-index: 0;
        }
      }

      .btn-container {
        @media (max-width: 767px) {
          position: absolute;
          bottom: 0.5em;
          z-index: 1;
          left: 50%;
          &:first-child {
            margin-left: -4.5em;
          }
          &:last-child {
            margin-left: 0.5em;
          }
        }

        @media (min-width: 768px) {
          position: relative;
          height: 100%;
          &:last-child {
            float: right;
          }
        }

        .btn-back, .btn-next {

          line-height: 1.95em;
          width: 2em;
          height: 2em;
          font-size: 1.5em;
          cursor: pointer;
          @media (min-width: 768px) {
            font-size: 1.5em;
            position: absolute;
            top: 50%;
            margin-top: -50%;
          }
          @media (min-width: 992px) {
            font-size: 2em;
          }
          @media (min-width: 1200px) {
            font-size: 3em;
          }
          background: #fafafa;
          background-color: rgba(255, 255, 255, 0.25);
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            background: #fefefe;
            background-color: rgba(255, 255, 255, 0.5);
            color: rgba(255, 255, 255, 0.75);
          }
          @include border-radius(50%);
          &:before {
            text-align: center;
            vertical-align: middle;
          }
        }
        .btn-back {
          text-align: center;
          &:before {
            margin-left: .1em;
          }
        }
        .btn-next {
          text-align: center;
          &:before {
            margin-left: .3em;
          }
        }
      }

      div.txt {
        padding: 0;
        height: 100%;
        position: relative;
        @media (min-width: 768px) {
          padding: 0 2em;
          background-color: darken($blue, 30); /* fallback ie8 */
          background: rgba(0, 0, 0, 0.7);
        }

        a.lp3-textlink {
          color: white;
          &:after {
            border-bottom-color: white;
          }
        }
        p {
          padding-bottom: 0.1em;
          a.lp3-textlink {
            color: white;
            &:after{
              border-bottom: 1px solid white;
            }

          }
        }
        h1 {
          white-space: nowrap;
        }
      }

      div.img {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 6rem;
        .ref-deco-img {
          img {
            width: 100%;
          }
        }
      }
    }

    ul.head-checklist {
      @media(max-width: 767px) {
        display: none;
      }
      padding: 0;
      margin: 0;
      position: absolute;
      bottom: 2em;
      right: 3em;
      font-size: 1.25em;
      list-style-type: none;
      color: #fafafa;
      z-index: 2;
      li {
        padding: 0;
        margin: 0;
        line-height: 1.5em;
        a {
          color: white;
          text-decoration: none;
          &:after {
            border-bottom: 1px solid white;
          }
        }

      }
    }
  }
}

ul.topic-nav {
  margin: 0 -1em;
  padding: 0;
  list-style-type: none;
  text-align: center;
  border-top: 1px solid white;
  background-color: darken($blue, 40); /* fallback ie8 */
  background: rgba(0, 0, 0, 0.75);
  z-index: 3;
  @include box-shadow("0 2px 3px rgba(0,0,0,1)");
  li {
    display: inline;
    margin: 0;
    a {
      @include transition('all .25s');

      border-top: 3px solid transparent;
      display: inline-block;
      color: white;
      font-size: 1rem;

      padding: 10px 20px;
      text-decoration: none;

      &:hover {
        border: 0;
        border-top: 3px solid white;
      }
    }
    @media (max-width: 767px) {
      display: block;
      text-align: center;
      a {
        border: 0;
        &:hover {
          border: 0;
        }
      }
    }
  }
}

ul.ref-row {
  @extend .clearfix;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    float: left;
    width: 33.3333333333%;
    height: 0;

    padding-top: 18.5185%;
    margin: 0 !important;
    border: 1px solid #e0e0e0;
    background: #eaeaea center center no-repeat;
    background-size: 80%;
    &:last-child {
      display: none;
    }
  }
  @media (min-width: 768px) {
    li {
      width: 14.285714286%;
      padding-top: 7.936428572%;
      &:last-child {
        display: inline-block;
      }
    }
  }
}

.lp3-ref-label {
  color: #333;
}

.content-block {
  margin-top: 50px;
  h2, h3, h4 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    //a{ text-decoration: none; color: $blue; }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0.5rem;
    font-size: 1.1rem;
  }
  h4 {
    margin-bottom: 0;
  }

  .ref-deco-img {
    img {
      width: 100%;
    }
  }
  .container, .reasons {
    ul {
      text-align: justify;
      @include hyphens(auto);
    }

  }

}

.feat-block {
  color: $blue;
  text-decoration: none;
  display: block;
  padding: 10px;
  margin: 10px;
  border: 1px solid $lightgrey;
  text-align: center;
  vertical-align: top;
  line-height: 1.5em;
  @include transition(all 0.25s);
  background-color: #f0f0f0;
  &:hover {
    background-color: #f8f8f8;
    .h3, i {
      color: lighten($blue, 10%);
    }
  }
  i {
    font-size: 4rem;
    display: block;;
    @include transition(all 0.25s);

  }
  .h3 {
    display: block;
    font-size: 1.5em;
    line-height: 2em;
    @include transition(all 0.25s);
  }
  .txt {
    color: $txt-grey;
  }

}

.lp-pi hr {
  display: block;
  margin-top: 5em;
  padding-bottom: 5em;
}

.col-xs-12.col-sm-4{
  height:15rem;
  margin-bottom:1rem;
  .feat-block{
    height:100%;
  }
}
